import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { PURGE } from "redux-persist";
import jwt_decode, { JwtPayload } from "jwt-decode";

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  isLoading: boolean;
  isLoggedIn: boolean;
  user: { email: string } | null;
}

const initialAuthState: AuthState = {
  accessToken: null,
  refreshToken: null,
  isLoading: true,
  isLoggedIn: false,
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialAuthState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    signIn: (
      state,
      action: PayloadAction<{ accessToken: string; refreshToken: string }>
    ) => {
      const decoded = jwt_decode<JwtPayload & { email?: string }>(
        action.payload.accessToken
      );
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.isLoading = false;
      state.isLoggedIn = true;
      state.user = {
        email: decoded?.email ?? "",
      };
    },
    signOut: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.isLoading = false;
      state.isLoggedIn = false;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      storage.setItem(
        "root",
        JSON.stringify({ ...state, auth: initialAuthState })
      );
    });
  },
});

export default authSlice;
