import { GroupResponse, GroupUpdateRequest } from "../types/types";
import { api } from "./api";
import { getEnv } from "../getEnv";

const basePath = `${getEnv().API_PATH_GROUPS}/groups`;

const groupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query<GroupResponse[], void>({
      query: () => ({
        url: `${basePath}/all`,
        method: "GET",
      }),
      providesTags: ["Group"],
    }),
    getGroup: builder.query<GroupResponse, string>({
      query: (id) => ({
        url: `${basePath}/${id}`,
        method: "GET",
      }),
    }),
    createGroup: builder.mutation<GroupResponse, GroupUpdateRequest>({
      query: (req) => ({
        url: basePath,
        method: "POST",
        body: req,
      }),
      invalidatesTags: ["Group"],
    }),
    updateGroup: builder.mutation<
      GroupResponse,
      { id: string; request: GroupUpdateRequest }
    >({
      query: (req) => ({
        url: `${basePath}/${req.id}`,
        method: "PUT",
        body: req.request,
      }),
      invalidatesTags: ["Group"],
    }),
    deleteGroup: builder.mutation<GroupResponse, { id: string }>({
      query: (req) => ({
        url: `${basePath}/${req.id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Group"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGroupQuery,
  useGetGroupsQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
} = groupApi;
