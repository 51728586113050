import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import NavigationDrawer from "../drawer/NavigationDrawer";

/**
 * Todo: use auth context
 */
export default function ProtectedLayout() {
  const auth = useAppSelector(state => state.auth);


  if (auth && auth.isLoggedIn === false) {
    return (
      <Navigate
        to={{
          pathname: "/signin",
        }}
      />
    );
  }

  return (
    <NavigationDrawer>
      <Outlet />
    </NavigationDrawer>
  );
}
