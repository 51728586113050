import React from "react";
import "./App.css";
import { IntlProvider } from "react-intl";
import { translations } from "./lang/messages";
import { routesConfig } from "./routing/routes";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import lightTheme from "./themes/light-theme";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";

function App() {
  const routes = useRoutes(routesConfig);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider locale={"en"} messages={translations["en"]}>
          <ThemeProvider theme={lightTheme}>
            <SnackbarProvider maxSnack={3}>
              <ConfirmProvider>{routes}</ConfirmProvider>
            </SnackbarProvider>
          </ThemeProvider>
        </IntlProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
