import { Grid, Typography, Button } from "@mui/material";
import { useIntl } from "react-intl";
import { TimeInput } from "../../components/common";
import { useGetCustomerDataQuery } from "../../services/customers";
import useToggle from "../../hooks/useToggle";
import { EditSilentPeriodDialog } from "./components/EditSilentPeriodDialog";
import { useMemo } from "react";
import { useCallback } from "react";
import { Loadable } from "../../components/loadable/Loadable";

export interface ISettingsProps {}

export default function Settings(props: ISettingsProps) {
  const { data: customerData, isLoading } = useGetCustomerDataQuery();
  const intl = useIntl();
  const [isEditFormOpen, setIsEditFormOpen] = useToggle(false);

  const isValidNumber = (val: number | null | undefined) => {
    if (val !== null && val !== undefined) {
      return true;
    }
    return false;
  };

  const aggregateHours = useCallback(
    (hours: number | undefined, minutes: number | undefined) => {
      if (isValidNumber(hours) && isValidNumber(minutes))
        return `${hours}:${minutes}`;

      return null;
    },
    []
  );

  const startTime = useMemo(
    () =>
      aggregateHours(
        customerData?.silentPeriodStartHour,
        customerData?.silentPeriodStartMinute
      ),
    [customerData, aggregateHours]
  );

  const endTime = useMemo(
    () =>
      aggregateHours(
        customerData?.silentPeriodEndHour,
        customerData?.silentPeriodEndMinute
      ),
    [customerData, aggregateHours]
  );

  return (
    <Grid>
      <Grid item>
        <Typography component="h5" variant="h5">
          {intl.formatMessage({
            id: "Settings.SilentPeriod.Header",
          })}
        </Typography>
        <Typography>
          {intl.formatMessage({
            id: "Settings.SilentPeriod.Content",
          })}
        </Typography>
      </Grid>
      <Grid
        container
        style={{
          justifyContent: "space-between",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <Loadable isLoading={isLoading}>
          <TimeInput disabled value={startTime} onChangeCallback={() => {}} />
          <Typography style={{ padding: "10px" }}>
            {intl.formatMessage({
              id: "Settings.SilentPeriod.To",
            })}
          </Typography>
          <TimeInput disabled value={endTime} onChangeCallback={() => {}} />
          <Grid
            container
            item
            xs={12}
            md={4}
            alignSelf="center"
            justifyContent="flex-end"
            padding="5px"
          >
            <Button onClick={() => setIsEditFormOpen(true)} variant="outlined">
              {intl.formatMessage({
                id: "Settings.SilentPeriod.EditButton",
              })}
            </Button>
          </Grid>
        </Loadable>
      </Grid>
      <EditSilentPeriodDialog
        key={`${customerData?.id}${startTime}${endTime}`}
        isOpen={isEditFormOpen}
        onCloseCallback={() => setIsEditFormOpen(false)}
        startTime={startTime}
        endTime={endTime}
      />
    </Grid>
  );
}
