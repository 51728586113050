import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { useCreateGroupMutation } from "../../../services/groups";
import { GroupResponse, GroupCreateRequest } from "../../../types/types";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { FastField, FieldProps, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import VisibilitySwitch from "./VisibilitySwitch";

interface IAddTeamDialog {
  onClose: () => void;
  open: boolean;
}
const AddTeamDialog = (props: IAddTeamDialog) => {
  const { onClose: handleClose, open } = props;
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [createGroup] = useCreateGroupMutation();

  const handleCreateGroup = (
    values: GroupCreateRequest,
    helpers: FormikHelpers<GroupCreateRequest>
  ) => {
    const { resetForm } = helpers;
    createGroup({
      name: values.name.trim(),
      description: values.description,
      isPrivate: values.isPrivate,
    })
      .unwrap()
      .then((res: GroupResponse) => {
        enqueueSnackbar(
          intl.formatMessage({ id: "TeamManagement.AddTeam.Success" }),
          { variant: "success" }
        );
        resetForm();
        handleClose();
      })
      .catch((err: FetchBaseQueryError | SerializedError) => {
        if ("data" in err && err.status === 409) {
          enqueueSnackbar(
            intl.formatMessage({
              id: "TeamManagement.AddTeam.Error.TeamExists",
            }),
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(
            intl.formatMessage({ id: "TeamManagement.AddTeam.Error" }),
            { variant: "error" }
          );
        }
      });
  };

  return (
    <Formik
      initialValues={{ name: "", description: "", isPrivate: false }}
      validationSchema={yup.object().shape({
        name: yup
          .string()
          .min(1, (val) =>
            intl.formatMessage(
              { id: "Validation.MinLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "TeamManagement.Common.TeamName",
                }),
                x: val.min,
              }
            )
          )
          .max(32, (val) =>
            intl.formatMessage(
              { id: "Validation.MaxLengthNamed" },
              {
                name: intl.formatMessage({
                  id: "TeamManagement.Common.TeamName",
                }),
                x: val.max,
              }
            )
          )
          .required(intl.formatMessage({ id: "Validation.FieldRequired" })),
      })}
      onSubmit={handleCreateGroup}
    >
      {({ handleSubmit, errors }) => (
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>
            {intl.formatMessage({
              id: "TeamManagement.AddTeam.HelpText.CreateTeam",
            })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText paddingBottom={2}>
              {intl.formatMessage({ id: "TeamManagement.AddTeam.HelpText" })}
            </DialogContentText>
            <Grid container spacing={1}>
              <Grid item xs={9}>
                <FastField name="name">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="outlined"
                      label={intl.formatMessage({
                        id: "TeamManagement.Common.TeamName",
                      })}
                      required
                      inputRef={(input) => input && input.focus()}
                      error={!!errors.name}
                      helperText={errors.name}
                    />
                  )}
                </FastField>
              </Grid>
              <Grid item xs={3} sx={{ textAlign: "center" }}>
                <Typography variant="caption" component="span" textTransform={"uppercase"}>
                  {intl.formatMessage({
                    id: "TeamManagement.Common.PrivateTeam",
                  })}:
                </Typography>
                <FastField name="isPrivate">
                  {({ field, form }: FieldProps) => (
                    <VisibilitySwitch
                      {...field}
                      checked={field.value}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                        checked
                      ) => form.setFieldValue("isPrivate", checked)}
                    />
                  )}
                </FastField>
              </Grid>
              <Grid item xs={12}>
                <FastField name="description">
                  {({ field }: FieldProps) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      maxRows={5}
                      variant="outlined"
                      label={intl.formatMessage({
                        id: "TeamManagement.Common.Description",
                      })}
                      error={!!errors.description}
                      helperText={errors.description}
                    />
                  )}
                </FastField>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={(event) => handleSubmit()}>
              {intl.formatMessage({
                id: "TeamManagement.AddTeam.Button.AddTeam",
              })}
            </Button>
            <Button variant="outlined" color="error" onClick={handleClose}>
              {intl.formatMessage({ id: "Common.Button.Cancel" })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};

export default AddTeamDialog;
