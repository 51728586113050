import { RouteObject } from "react-router-dom";
import {
  Home,
  Signin,
  UserManagement,
  Support,
  TeamManagement,
} from "../pages";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import FlagIcon from "@mui/icons-material/Flag";
import GppGoodIcon from "@mui/icons-material/GppGood";
import GroupsIcon from "@mui/icons-material/Groups";
import TagIcon from "@mui/icons-material/Tag";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import UnprotectedLayout from "../components/layout/UnprotectedLayout";
import ProtectedLayout from "../components/layout/ProtectedLayout";
import Activities from "../pages/Activities/Activities";
import Goals from "../pages/Goals/Goals";
import Settings from "../pages/Settings/Settings";
import TagManagement from "../pages/TagManagement/TagManagement";
import Dashboard from "../pages/Dashboard/Dashboard";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";

export interface RouteConfig extends RouteObject {
  children?: RouteConfig[];
  meta?: {
    sidebarId?: string;
    icon?: React.ReactNode;
  };
}

//Routes shown in sidebar menu
const dashboardRoutes: RouteConfig[] = [
  {
    path: "/dashboard",
    element: <Dashboard />,
    meta: {
      sidebarId: "Menu.Item.Dashboard",
      icon: <DashboardIcon />,
    },
  },
  {
    path: "/dashboard/users",
    element: <UserManagement />,
    meta: {
      sidebarId: "Menu.Item.UserManagement",
      icon: <ManageAccountsIcon />,
    },
  },
  {
    path: "/dashboard/teams",
    element: <TeamManagement />,
    meta: {
      sidebarId: "Menu.Item.Teams",
      icon: <GroupsIcon />,
    },
  },
  {
    path: "/dashboard/categories",
    element: <TagManagement />,
    meta: {
      sidebarId: "Menu.Item.Categories",
      icon: <TagIcon />,
    },
  },
  {
    path: "/dashboard/activities",
    element: <Activities />,
    meta: {
      sidebarId: "Menu.Item.Activities",
      icon: <GppGoodIcon />,
    },
  },
  {
    path: "/dashboard/goals",
    element: <Goals />,
    meta: {
      sidebarId: "Menu.Item.Goals",
      icon: <FlagIcon />,
    },
  },
];

const protectedRoutes: RouteConfig = {
  path: "/dashboard",
  element: <ProtectedLayout />,
  children: [
    ...dashboardRoutes,
    {
      path: "/dashboard/activities/:id",
      element: <Activities />,
    },
    {
      path: "/dashboard/tags/:id",
      element: <TagManagement />,
    },
    {
      path: "/dashboard/teams/:id",
      element: <TeamManagement />,
    },
  ],
};

const accountRoutes: RouteConfig = {
  path: "/account",
  element: <ProtectedLayout />,
  children: [
    {
      path: "/account/settings",
      element: <Settings />,
      meta: {
        sidebarId: "SubMenu.Item.Settings",
        icon: <SettingsIcon />,
      },
    },
    //other subroutes
  ],
};

const supportRoute: RouteConfig = {
  path: "/support",
  element: <Support />,
  meta: {
    sidebarId: "SubMenu.Item.Support",
    icon: <SupportAgentIcon />,
  },
};

const routesConfig: RouteConfig[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/",
    element: <UnprotectedLayout />,
    children: [
      {
        path: "signin",
        element: <Signin />,
      },
    ],
  },
  protectedRoutes,
  ...dashboardRoutes,
  accountRoutes,
  supportRoute,
];

export { routesConfig, dashboardRoutes, accountRoutes, supportRoute };
