import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useIntl } from "react-intl";
import { GroupResponse } from "../../../types/types";
import CloseIcon from "@mui/icons-material/Close";
import { UserTable } from "./UserTable";

interface ITeamDetailDialog {
  onClose: () => void;
  open: boolean;
  group: GroupResponse;
}
const TeamDetailDialog = (props: ITeamDetailDialog) => {
  const { onClose: handleClose, open, group } = props;
  const intl = useIntl();

  return (
    <Dialog fullWidth={true} maxWidth={"md"} onClose={handleClose} open={open}>
      <Card>
        <CardHeader
          title={
            <Tooltip title={group.name} placement="top-start">
              <Typography noWrap variant="h5">
                {group.name}
              </Typography>
            </Tooltip>
          }
          sx={{
            display: "flex",
            overflow: "hidden",
            "& .MuiCardHeader-content": {
              overflow: "hidden",
            },
          }}
          action={
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          }
        />
        <Divider />
        <CardContent>
          <Grid container columnSpacing={3}>
            <Grid item xs={6}>
              <Typography variant={"subtitle2"} gutterBottom>
                {intl.formatMessage({
                  id: "Common.Label.Description",
                })}
              </Typography>
              <Typography variant="body1" gutterBottom sx={{ maxHeight: 450, overflow: "scroll" }}>
                {group.description}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={"subtitle2"} gutterBottom>
                {intl.formatMessage({
                  id: "TeamManagement.Common.Users",
                })}
              </Typography>
              <UserTable members={group.groupUsers} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Dialog>
  );
};

export default TeamDetailDialog;
