import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";
import { Formik, FormikHelpers } from "formik";
import React, { FC } from "react";
import { Loadable } from "../../../components/loadable/Loadable";
import * as yup from "yup";
import { FormTimeInput } from "./Inputs/FormTimeInput";
import { useUpdateCustomerDataMutation } from "../../../services/customers";

interface IEditSilentPeriodDialog {
  onCloseCallback: () => void;
  isOpen: boolean;
  startTime: string | null;
  endTime: string | null;
}

interface IEditForm {
  startTime: string | null;
  endTime: string | null;
}

export const EditSilentPeriodDialog: FC<IEditSilentPeriodDialog> = ({
  onCloseCallback: handleCloseDialog,
  isOpen,
  startTime,
  endTime,
}) => {
  const {formatMessage: fMessage} = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [updateCustomerData, { isLoading }] = useUpdateCustomerDataMutation();

  const parseTimeInput = (val: string | null) => {
    if (val) {
      const [hours, minutes] = val.split(":");
      return { hour: parseInt(hours), minutes: parseInt(minutes) };
    }
    return null;
  };

  const handleEditSilentPeriod = (
    values: IEditForm,
    helpers: FormikHelpers<IEditForm>
  ) => {
    const start = parseTimeInput(values.startTime);
    const end = parseTimeInput(values.endTime);
    updateCustomerData({
      silentPeriodStartHour: start ? start.hour : null,
      silentPeriodStartMinute: start ? start.minutes : null,
      silentPeriodEndMinute: end ? end.minutes : null,
      silentPeriodEndHour: end ? end.hour : null,
    })
      .unwrap()
      .then(() => {
        const { resetForm } = helpers;

        enqueueSnackbar(
          fMessage({ id: "Settings.SilentPeriod.Form.Success" }),
          { variant: "success" }
        );
        resetForm();
      })
      .catch((err: FetchBaseQueryError | SerializedError) => {
        enqueueSnackbar(
          fMessage({ id: "Settings.SilentPeriod.Form.Error" }),
          { variant: "error" }
        );
      })
      .finally(() => handleCloseDialog());
  };

  return (
    <Formik
      initialValues={{
        startTime: startTime,
        endTime: endTime,
      }}
      onSubmit={handleEditSilentPeriod}
      validationSchema={yup.object().shape({
        startTime: yup
          .string()
          .nullable()
          .test(
            "defined",
            fMessage({ id: "Validation.FieldRequired" }),
            function (value) {
              if (this.parent.endTime !== null) {
                return value !== null;
              }
              return true;
            }
          ),
        endTime: yup
          .string()
          .nullable()
          .test(
            "defined",
            fMessage({ id: "Validation.FieldRequired" }),
            function (value) {
              if (this.parent.startTime !== null) {
                return value !== null;
              }
              return true;
            }
          ),
      })}
    >
      {({ handleSubmit, errors, setFieldValue, setValues }) => (
        <Dialog onClose={handleCloseDialog} open={isOpen}>
          <DialogTitle>
            {fMessage({
              id: "Settings.SilentPeriod.Form.Header",
            })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText paddingBottom={2}>
              {fMessage({
                id: "Settings.SilentPeriod.Form.Explanatory",
              })}
            </DialogContentText>
            <FormTimeInput
              onChange={(val) => setFieldValue("startTime", val)}
              hasError={!!errors.startTime}
              helperText={errors.startTime as string}
              name="startTime"
              label={fMessage({
                id: "Settings.SilentPeriod.Form.Start",
              })}
            />
            <FormTimeInput
              onChange={(val) => setFieldValue("endTime", val)}
              hasError={!!errors.endTime}
              helperText={errors.endTime as string}
              name="endTime"
              label={fMessage({
                id: "Settings.SilentPeriod.Form.End",
              })}
            />
            <Loadable isLoading={isLoading} />
            <Stack direction="row" spacing={2} sx={{ marginTop: 2 }}>
              <Button
                variant="outlined"
                onClick={() => {
                  setValues({ startTime: null, endTime: null });
                }}
              >
                {fMessage({
                  id: "Settings.SilentPeriod.ClearButton",
                })}
              </Button>
              <Typography variant="caption" component="span">
                {fMessage({
                  id: "Settings.SilentPeriod.Form.ClearIntent",
                })}
              </Typography>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={(event) => handleSubmit()}>
              {fMessage({
                id: "Settings.SilentPeriod.Form.Submit",
              })}
            </Button>
            <Button variant="outlined" onClick={handleCloseDialog}>
              {fMessage({
                id: "Common.Button.Cancel",
              })}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Formik>
  );
};
