import { CustomerDataResponse, CustomerDataRequest } from "../types/types";
import { api } from "./api";
import { getEnv } from "../getEnv";

const basePath = `${getEnv().API_PATH_GROUPS}/groups/customers`;

const customersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCustomerData: builder.query<CustomerDataResponse, void>({
      query: () => ({
        url: basePath,
        method: "GET",
      }),
      providesTags: ["Customer"],
    }),
    updateCustomerData: builder.mutation<
      CustomerDataResponse,
      CustomerDataRequest
    >({
      query: (req) => ({
        url: basePath,
        method: "PUT",
        body: req,
      }),
      invalidatesTags: ["Customer"],
    }),
  }),
  overrideExisting: false,
});

export const { useGetCustomerDataQuery, useUpdateCustomerDataMutation } =
  customersApi;
